import { ElementConfig } from '../types';
import {
  STORY_PREVIEW_DEFAULT_ANIMATIONS,
  STORY_PREVIEW_DEFAULT_BORDER_COLOR,
  STORY_PREVIEW_DEFAULT_DISPLAY,
  STORY_PREVIEW_ELEMENT_NAME,
} from './pimster-story-preview.constants';

const pimsterStoryPreviewConfig: ElementConfig = {
  name: STORY_PREVIEW_ELEMENT_NAME,
  attributes: [
    {
      name: 'animations',
      defaultValue: STORY_PREVIEW_DEFAULT_ANIMATIONS,
    },
    {
      name: 'backgroundImage',
      defaultValue: '',
    },
    {
      name: 'borderColor',
      defaultValue: STORY_PREVIEW_DEFAULT_BORDER_COLOR,
    },
    {
      name: 'display',
      defaultValue: STORY_PREVIEW_DEFAULT_DISPLAY,
    },
    {
      name: 'text',
      defaultValue: '',
    },
    {
      name: 'video',
      defaultValue: undefined,
    },
  ],
};

export default pimsterStoryPreviewConfig;
