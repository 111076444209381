import { TRIGGER_CONFIG_BASE_ATTRIBUTES } from '../constants';
import { ElementConfig } from '../types';
import { TRIGGER_ELEMENT_NAME } from './pimster-trigger.constants';

const pimsterTriggerConfig: ElementConfig = {
  name: TRIGGER_ELEMENT_NAME,
  attributes: [
    ...TRIGGER_CONFIG_BASE_ATTRIBUTES,
    {
      name: 'module',
      defaultValue: undefined,
    },
    {
      name: 'story',
      defaultValue: undefined,
    },
    {
      name: 'storyOrder',
      defaultValue: undefined,
    },
  ],
};

export default pimsterTriggerConfig;
