import { CookieConsent } from '../enums';
import {
  STORY_PREVIEW_DEFAULT_BORDER_COLOR,
  STORY_PREVIEW_DEFAULT_DISPLAY,
  STORY_PREVIEW_DEFAULT_ANIMATIONS,
} from '../pimster-story-preview/pimster-story-preview.constants';
import { ElementConfig } from '../types';
import { GALLERY_WIDGET_ELEMENT_NAME } from './pimster-gallery-widget.constants';

const pimsterGalleryWidgetConfig: ElementConfig = {
  name: GALLERY_WIDGET_ELEMENT_NAME,
  attributes: [
    {
      name: 'company',
      defaultValue: '',
    },
    {
      name: 'product',
      defaultValue: '',
    },
    {
      name: 'moduleId',
      defaultValue: '',
    },
    {
      name: 'withTitle',
      defaultValue: false,
    },
    {
      name: 'display',
      defaultValue: STORY_PREVIEW_DEFAULT_DISPLAY,
    },
    {
      name: 'animations',
      defaultValue: STORY_PREVIEW_DEFAULT_ANIMATIONS,
    },
    {
      name: 'borderColor',
      defaultValue: STORY_PREVIEW_DEFAULT_BORDER_COLOR,
    },
    {
      name: 'cookieConsent',
      defaultValue: CookieConsent.Ask,
    },
  ],
};

export default pimsterGalleryWidgetConfig;
