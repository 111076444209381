import { css } from 'lit';

export const pimsterDialogStyle = css`
  --max-width: 748px;
  .wrapper {
    opacity: 0;
    transition: visibility 0s, opacity 0.25s ease-in;
    z-index: 99999;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrapper:not(.open) {
    visibility: hidden;
    display: none;
  }
  .wrapper.open {
    position: fixed;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    visibility: visible;
  }
  .overlay {
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  iframe,
  .skeleton {
    border: none;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
  }
  #icon {
    color: white;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 1rem;
    right: 1rem;
  }
  #icon:hover {
    transition: opacity 0.25s ease-in;
    opacity: 0.7;
  }

  .dialog {
    position: fixed;
    max-width: var(--max-width);
    aspect-ratio: 9 / 16;
    height: 90vh;
    animation: scaleUp 0.3s ease-in-out;
  }

  @media (max-width: 748px) {
    .dialog {
      position: fixed;
      bottom: 0;
      max-height: calc(100vh - 4rem);
      width: 100%;
    }

    iframe,
    .skeleton {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  @media (min-width: 748px) {
    .dialog {
      aspect-ratio: 9 / 16;
      max-height: 80vh;
    }
  }

  .skeleton {
    background-color: whitesmoke;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .skeleton-header,
  .skeleton-footer {
    display: flex;
    gap: 10px;
    padding: 18px;
  }

  .skeleton-header {
    flex-direction: row;
    align-items: center;
  }

  .skeleton-footer {
    flex-direction: column;
    align-items: start;
  }

  .skeleton-circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: linear-gradient(
      90deg,
      whitesmoke 25%,
      #dddddd 50%,
      whitesmoke 75%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }

  .skeleton-text {
    height: 17px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
  }

  .skeleton-text.short {
    width: 70%;
  }

  .skeleton-text.long {
    width: 90%;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }

  @keyframes scaleUp {
    0% {
      transform: scale(0.2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
