/* eslint-disable no-shadow */

import { css } from 'lit';

export enum StoryPreviewDisplay {
  Round = 'round',
  Square = 'square',
}

export enum StoryPreviewAnimation {
  Autoplay = 'autoplay',
  OnHover = 'onHover',
  Pulse = 'pulse',
}

export const STORY_PREVIEW_ELEMENT_NAME = 'pimster-story-preview';
export const STORY_PREVIEW_DEFAULT_BORDER_COLOR = 'black';
export const STORY_PREVIEW_DEFAULT_DISPLAY = StoryPreviewDisplay.Square;
export const STORY_PREVIEW_DEFAULT_ANIMATIONS: StoryPreviewAnimation[] = [];
export const STORY_PREVIEW_ANIMATION_DURATION_IN_SECONDS = 3;
export const STORY_PREVIEW_ANIMATION_DURATION_IN_MS =
  STORY_PREVIEW_ANIMATION_DURATION_IN_SECONDS * 1000;
export const SQUARE_STORY_PREVIEW_DISPLAY_HEIGHT = css`
  height: 15em;
`;
export const ROUND_STORY_PREVIEW_DISPLAY_HEIGHT = css`
  height: 8.5em;
`;
