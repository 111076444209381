import { StyleInfo } from 'lit/directives/style-map';
import {
  STORY_PREVIEW_ELEMENT_NAME,
  StoryPreviewAnimation,
  StoryPreviewDisplay,
} from './pimster-story-preview.constants';
import { StoryPreviewEvent } from './pimster-story-preview.types';

export const getImageStyle = (url: string): StyleInfo => ({
  'background-image': `url(${url})`,
});

export const buildStoryPreviewEvent = <T extends StoryPreviewEvent>(
  name: T['name'],
  detail: Omit<T, 'name'>
) =>
  new CustomEvent<Omit<T, 'name'>>(`${STORY_PREVIEW_ELEMENT_NAME}-${name}`, {
    detail,
    bubbles: true,
    composed: true,
  });

export const isSquare = (display: StoryPreviewDisplay) =>
  display === StoryPreviewDisplay.Square;

export const isAutoplay = (animation: StoryPreviewAnimation) =>
  animation === StoryPreviewAnimation.Autoplay;

export const isOnHover = (animation: StoryPreviewAnimation) =>
  animation === StoryPreviewAnimation.OnHover;

export const isPulse = (animation: StoryPreviewAnimation) =>
  animation === StoryPreviewAnimation.Pulse;

export const hasAutoplay = (animations: StoryPreviewAnimation[]) =>
  animations.includes(StoryPreviewAnimation.Autoplay);

export const hasOnHover = (animations: StoryPreviewAnimation[]) =>
  animations.includes(StoryPreviewAnimation.OnHover);

export const hasPulse = (animations: StoryPreviewAnimation[]) =>
  animations.includes(StoryPreviewAnimation.Pulse);

export const withVideo = (animations: StoryPreviewAnimation[]) =>
  hasAutoplay(animations) || hasOnHover(animations);
