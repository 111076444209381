import Analytics, { AnalyticsInstance } from 'analytics';
import { GLOBAL_ANALYTICS_KEY } from './constants';
import { pimsterDataPlugin } from './plugins/pimsterData';
import { contextPlugin } from './plugins/context';

const initAnalytics = () => {
  if (window.__pimsterDisableAnalytics === true) return undefined;
  try {
    const analytics = Analytics({
      app: 'pimster-widget',
      plugins: [contextPlugin(), pimsterDataPlugin()],
    });

    window[GLOBAL_ANALYTICS_KEY] = analytics;
    return analytics;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    throw Error('[Analytics] Cannot retrieve analytics');
  }
};

export const analytics = initAnalytics();

export const getAnalytics = (): AnalyticsInstance | undefined => analytics;
