export interface RetryOptions<T> {
  action: () => T | Promise<T>;
  maxRetries?: number;
  retryInterval?: number;
}

export const retryUntilReturnOrThrow = <T>({
  action,
  maxRetries = 5,
  retryInterval = 1000,
}: RetryOptions<T>): Promise<T> => {
  let retryCount = 0;

  const attempt = () => {
    if (retryCount < maxRetries) {
      return new Promise<T>(resolve => {
        setTimeout(async () => {
          retryCount += 1;

          try {
            const result = await action();
            resolve(result);
          } catch (error) {
            attempt();
          }
        }, retryInterval);
      });
    }

    throw new Error('Could not accomplish action despite retries');
  };

  return attempt();
};
