type EventArgs = {
  payload: any;
  config: any;
};

export const contextPlugin = () => ({
  name: 'PageDataEnrichment',
  version: '1.0.0',
  config: {
    // Persiste client data at load time
    context: {
      userAgent: window.navigator.userAgent,
      locale: window.navigator.language,
      library: { name: 'analytics.js' }, // Assuming this is fixed, change as needed
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  },
  isLoaded: () => true,
  trackStart: ({ payload, config }: EventArgs) => {
    const pageData = {
      path: window.location.pathname, // Current path
      referrer: document.referrer, // Referrer URL
      search: window.location.search, // Query string
      title: document.title, // Page title
      url: window.location.href, // Full URL
      hash: window.location.hash, // Fragment identifier
    };

    // eslint-disable-next-line no-param-reassign
    payload.context = { page: pageData, ...config.context };
  },
});
