import { html } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { PimsterStoryPreviewRenderProps } from './pimster-story-preview.types';
import { getImageStyle } from './pimster-story-preview.utils';

export const roundStoryPreviewTemplate = ({
  backgroundImage,
  isHovered,
  isPlaying,
  isPulsating,
  isInsideAutoplay,
  text,
  video,
  withPlayIcon,
}: Omit<PimsterStoryPreviewRenderProps, 'display'>) => {
  const isOpaque = isInsideAutoplay && !isPlaying;

  return html`
    <div class="round-wrapper">
      <div class="round-content-wrapper ${isPulsating ? 'pulsating' : ''}">
        <div
          class="round-background-image ${isOpaque ? 'opaque' : ''}"
          style=${styleMap({ ...getImageStyle(backgroundImage) })}
        >
          ${withPlayIcon
            ? html`
                <svg
                  class="play-icon round  ${isHovered || isPlaying
                    ? 'hidden'
                    : ''}"
                  viewBox="0 0 100 100"
                >
                  <path fill="white" d="M 35 30 L 35 70 L 70 50 Z" />
                </svg>
              `
            : ''}
          ${video
            ? html`
                <video
                  class="round-video"
                  src="${video}"
                  muted
                  playsinline
                  loading="lazy"
                  preload="metadata"
                ></video>
              `
            : ''}
        </div>
      </div>
      <p class="round-text text">${text}</p>
    </div>
  `;
};

export const squareStoryPreviewTemplate = ({
  backgroundImage,
  isHovered,
  isPlaying,
  text,
  video,
  withPlayIcon,
}: Omit<
  PimsterStoryPreviewRenderProps,
  'display' | 'isInsideAutoplay' | 'isPulsating'
>) => html`
  <div
    class="square-wrapper"
    style=${styleMap({ ...getImageStyle(backgroundImage) })}
  >
    ${video
      ? html`
          <video
            class="square-video"
            src="${video}"
            muted
            playsinline
            preload="metadata"
          ></video>
        `
      : ''}
    <div class="square-overlay ${isHovered || isPlaying ? 'hidden' : ''}">
      ${withPlayIcon
        ? html`
            <svg
              class="play-icon square ${isHovered || isPlaying ? 'hidden' : ''}"
              viewBox="0 0 100 100"
            >
              <path fill="white" d="M 35 30 L 35 70 L 70 50 Z" />
            </svg>
          `
        : ''}
      <p class="square-text text ${isHovered || isPlaying ? 'hidden' : ''}">
        ${text}
      </p>
    </div>
  </div>
`;
