/* global NodeListOf */
/* global EventListener */

import { html, LitElement } from 'lit';
import { Task, TaskStatus } from '@lit/task';
import { customElement, property, query, queryAll } from 'lit/decorators.js';
import pimsterGalleryWidgetConfig from './pimster-gallery-widget.config';
import { pimsterGalleryWidgetStyle } from './pimster-gallery-widget.style';
import pimsterGalleryWidgetRender from './pimster-gallery-widget.render';
import { PimsterStoryPreview } from '../pimster-story-preview';
import { PimsterTrigger } from '../pimster-trigger';
import {
  GetDynamicStoryPreviewsModuleDocument,
  GetDynamicStoryPreviewsModuleQuery,
  GetStoryPreviewsModuleDocument,
  GetStoryPreviewsModuleQuery,
} from '../graphql/generated/graphql';
import {
  buildGalleryWidgetError,
  isValidDynamicStoryPreviewComponent,
  isValidStoryPreviewComponent,
  isValidStoryPreviewsModuleOrThrow,
} from './pimster-gallery-widget.utils';
import { client } from '../lib/urql/client';
import {
  STORY_PREVIEW_DEFAULT_BORDER_COLOR,
  STORY_PREVIEW_DEFAULT_DISPLAY,
  STORY_PREVIEW_DEFAULT_ANIMATIONS,
  StoryPreviewDisplay,
  StoryPreviewAnimation,
  STORY_PREVIEW_ELEMENT_NAME,
  STORY_PREVIEW_ANIMATION_DURATION_IN_MS,
} from '../pimster-story-preview/pimster-story-preview.constants';
import { getStrapiAssetUrlOrFallback } from '../utils/getters';
import { ValidStoryPreviewsModule } from './pimster-gallery-widget.types';
import { trackImpression } from '../analytics/events/impression';
import {
  StoryPreviewMouseEnterEvent,
  StoryPreviewMouseLeaveEvent,
} from '../pimster-story-preview/pimster-story-preview.types';
import { retryUntilReturnOrThrow } from '../utils/retry';
import {
  hasAutoplay,
  hasOnHover,
  hasPulse,
  isAutoplay,
  isPulse,
  withVideo,
} from '../pimster-story-preview/pimster-story-preview.utils';
import { CookieConsent } from '../enums';

const { name } = pimsterGalleryWidgetConfig;

@customElement(name)
class PimsterGalleryWidget extends LitElement {
  // Properties
  @property({ type: Array<StoryPreviewAnimation> })
  animations = STORY_PREVIEW_DEFAULT_ANIMATIONS;

  @property({ type: String })
  borderColor = STORY_PREVIEW_DEFAULT_BORDER_COLOR;

  @property({ type: String })
  company = '';

  @property({ type: `${CookieConsent}` })
  cookieConsent = CookieConsent.Ask;

  @property({ type: `${StoryPreviewDisplay}` })
  display = STORY_PREVIEW_DEFAULT_DISPLAY;

  @property({ type: String })
  moduleId = '';

  @property({ type: String })
  product = '';

  @property({ type: Boolean, converter: value => value === 'true' })
  noPlayIcon = false;

  @property({ type: Boolean, converter: value => value === 'true' })
  withTitle = false;

  // Queries
  @query('ul')
  scrollable!: HTMLUListElement;

  @queryAll(STORY_PREVIEW_ELEMENT_NAME)
  storyPreviews!: NodeListOf<PimsterStoryPreview>;

  // Private Properties
  private _isAutoplaying = false;

  private _autoplayInterval?: ReturnType<typeof setTimeout> = undefined;

  // Static Styles
  static styles = pimsterGalleryWidgetStyle;

  // Tasks
  private _getModuleTask = new Task(this, {
    task: async () => {
      const module = await this._fetchValidStoryPreviewsModuleOrThrow(
        this.moduleId
      );
      return {
        title: module.Title,
        stories: this._wrapStoryPreviewsInsideTriggers(module.Content),
      };
    },
    args: () => [],
  });

  // Getter Methods
  private _getNextStoryPreview(storyPreview: PimsterStoryPreview) {
    const storyPreviewIndex = Array.from(this.storyPreviews).findIndex(
      item => item === storyPreview
    );
    const nextStoryPreviewIndex =
      (storyPreviewIndex + 1) % this.storyPreviews.length;
    return this.storyPreviews[nextStoryPreviewIndex];
  }

  private async _waitForInitialStoryPreviewToConnectOrThrow() {
    this._waitForInitialStoryPreviewToConnectOrThrow =
      this._waitForInitialStoryPreviewToConnectOrThrow.bind(this);
    const initialStoryPreview = this.storyPreviews[0];
    if (initialStoryPreview.backgroundImage) return initialStoryPreview;
    if (initialStoryPreview.htmlVideo) return initialStoryPreview;
    return retryUntilReturnOrThrow<PimsterStoryPreview>({
      action: this._waitForInitialStoryPreviewToConnectOrThrow,
      retryInterval: 500,
    });
  }

  // Builder Methods
  private _wrapStoryPreviewsInsideTriggers(
    stories: ValidStoryPreviewsModule['Content']
  ) {
    return stories
      .filter(isValidStoryPreviewComponent)
      .map((storyComponent, index) => {
        const storyPreviewAnimations: StoryPreviewAnimation[] =
          this.animations.filter(a => !isAutoplay(a) && !isPulse(a));

        if (index === 0) {
          if (hasAutoplay(this.animations)) {
            storyPreviewAnimations.push(StoryPreviewAnimation.Autoplay);
          }

          if (hasPulse(this.animations)) {
            storyPreviewAnimations.push(StoryPreviewAnimation.Pulse);
          }
        }

        const story = storyComponent.Story;
        const storyPreview = new PimsterStoryPreview()
          .setAnimations(storyPreviewAnimations)
          .setBorderColor(this.borderColor)
          .setBackgroundImage(
            getStrapiAssetUrlOrFallback(
              story.PreviewImage.formats?.small ||
                story.PreviewImage.formats?.medium ||
                story.PreviewImage.formats?.large ||
                story.PreviewImage
            )
          )
          .setDisplay(this.display)
          .setIsInsideAutoplay(hasAutoplay(this.animations))
          .setNoPlayIcon(this.noPlayIcon)
          .setText(story.Title);

        if (
          withVideo(this.animations) &&
          isValidDynamicStoryPreviewComponent(storyComponent)
        ) {
          const video = storyComponent.Story.StoryPages[0].Media;
          storyPreview.setVideo(
            getStrapiAssetUrlOrFallback(
              video.formats?.sd_mp4 ||
                video.formats?.hd_mp4 ||
                video.formats?.full_hd_mp4 ||
                video
            )
          );
        }

        const trigger = new PimsterTrigger()
          .setCompany(this.company)
          .setProduct(this.product)
          .setLocale(story.locale)
          .setCookieConsent(this.cookieConsent)
          .setModule(this.moduleId)
          .setStory(story.Slug)
          .setStoryOrder(index + 1);
        trigger.appendChild(storyPreview);
        return trigger;
      });
  }

  // Event Handlers
  private _addListeners() {
    this.addEventListener(
      `${STORY_PREVIEW_ELEMENT_NAME}-mouseenter`,
      this._onMouseEnterStoryPreview as EventListener
    );
    this.addEventListener(
      `${STORY_PREVIEW_ELEMENT_NAME}-mouseleave`,
      this._onMouseLeaveStoryPreview as EventListener
    );
  }

  private _removeListeners() {
    this.addEventListener(
      `${STORY_PREVIEW_ELEMENT_NAME}-mouseenter`,
      this._onMouseEnterStoryPreview as EventListener
    );
    this.addEventListener(
      `${STORY_PREVIEW_ELEMENT_NAME}-mouseleave`,
      this._onMouseLeaveStoryPreview as EventListener
    );
  }

  private _onMouseEnterStoryPreview(
    event: CustomEvent<StoryPreviewMouseEnterEvent>
  ) {
    const { storyPreview } = event.detail;

    if (this._isAutoplaying) {
      this._stopAutoplay({ exception: storyPreview });
    }

    if (hasOnHover(this.animations)) {
      storyPreview.addAnimation(StoryPreviewAnimation.Autoplay);
    }

    if (hasPulse(this.animations)) {
      this._startPulse(storyPreview);
    }
  }

  private _onMouseLeaveStoryPreview(
    event: CustomEvent<StoryPreviewMouseLeaveEvent>
  ) {
    const { storyPreview } = event.detail;

    if (hasAutoplay(this.animations)) {
      this._startAutoplayOrThrow({
        index: this._getNextStoryPreview(storyPreview),
      });
    }

    storyPreview.removeAnimation(StoryPreviewAnimation.Autoplay);
    storyPreview.removeAnimation(StoryPreviewAnimation.Pulse);
  }

  private _onMouseLeave() {
    if (hasPulse(this.animations) && !hasAutoplay(this.animations)) {
      this._restartPulse();
    }
  }

  // Lifecycle Methods
  async connectedCallback() {
    super.connectedCallback();
    await this._getModuleTask.run();
    if (this._getModuleTask.status === TaskStatus.ERROR) return;
    await this._waitForInitialStoryPreviewToConnectOrThrow();
    trackImpression({
      widgetType: 'gallery',
      company: this.company,
      product: this.product,
      moduleId: this.moduleId,
      isAutoplay: hasAutoplay(this.animations),
    });
    this._addListeners();

    if (this.hasAttribute('animations')) {
      if (hasAutoplay(this.animations)) {
        this._startAutoplayOrThrow({ index: this.storyPreviews[0] });
      } else if (this._isAutoplaying) {
        this._stopAutoplay();
      }
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._removeListeners();
  }

  // Fetch Methods
  private async _fetchValidStoryPreviewsModuleOrThrow(id: string) {
    let _module;
    let _error;
    const _withVideo = withVideo(this.animations);

    if (_withVideo) {
      const { data, error } =
        await client.query<GetDynamicStoryPreviewsModuleQuery>(
          GetDynamicStoryPreviewsModuleDocument,
          { id }
        );
      _module = data?.module;
      _error = error;
    } else {
      const { data, error } = await client.query<GetStoryPreviewsModuleQuery>(
        GetStoryPreviewsModuleDocument,
        { id }
      );
      _module = data?.module;
      _error = error;
    }

    if (!_module || _error) {
      throw buildGalleryWidgetError(
        `could not fetch story module (with video: ${_withVideo})`
      );
    }

    isValidStoryPreviewsModuleOrThrow(_module, {
      company: this.company,
      product: this.product,
    });

    return _module;
  }

  // Autoplay Methods
  private async _startAutoplayOrThrow({
    index,
  }: {
    index: PimsterStoryPreview;
  }) {
    let _index = index;
    await this._autoplay({ index: _index });

    this._autoplayInterval = setInterval(async () => {
      if (!this._isAutoplaying) return;
      _index = this._getNextStoryPreview(_index);
      await this._autoplay({ index: _index });
    }, STORY_PREVIEW_ANIMATION_DURATION_IN_MS);
  }

  private async _autoplay({ index }: { index: PimsterStoryPreview }) {
    this._isAutoplaying = true;

    this.storyPreviews.forEach((storyPreview, i) => {
      if (storyPreview !== index) {
        storyPreview.removeAnimation(StoryPreviewAnimation.Autoplay);
        storyPreview.removeAnimation(StoryPreviewAnimation.Pulse);
      } else {
        storyPreview.addAnimation(StoryPreviewAnimation.Autoplay);

        if (hasPulse(this.animations)) {
          storyPreview.addAnimation(StoryPreviewAnimation.Pulse);
        }

        this._maybeScrollAutoplay(storyPreview, i);
      }
    });
  }

  private _stopAutoplay({
    exception,
  }: { exception?: PimsterStoryPreview } = {}) {
    this._isAutoplaying = false;
    clearInterval(this._autoplayInterval);
    this.storyPreviews.forEach(storyPreview => {
      if (storyPreview !== exception) {
        storyPreview.removeAnimation(StoryPreviewAnimation.Autoplay);
        storyPreview.removeAnimation(StoryPreviewAnimation.Pulse);
      }
    });
  }

  private _maybeScrollAutoplay(
    storyPreview: PimsterStoryPreview,
    index: number
  ) {
    if (index === 0) {
      this.scrollable.scrollLeft = 0;
    } else {
      const scrollableRect = this.scrollable.getBoundingClientRect();
      const storyPreviewRect = storyPreview.getBoundingClientRect();
      const scrollableWidth = scrollableRect.width;

      const storyPreviewLeftRelativeToScrollable =
        storyPreviewRect.left - scrollableRect.left;
      const storyPreviewRightRelativeToScrollable =
        storyPreviewLeftRelativeToScrollable + storyPreviewRect.width;

      const isFullyVisible =
        storyPreviewLeftRelativeToScrollable >= 0 &&
        storyPreviewRightRelativeToScrollable <= scrollableWidth;

      if (!isFullyVisible) {
        const newScrollPosition =
          this.scrollable.scrollLeft +
          storyPreviewLeftRelativeToScrollable -
          scrollableWidth +
          storyPreviewRect.width;
        this.scrollable.scrollLeft = newScrollPosition;
      }
    }
  }

  // Pulse Methods
  private _startPulse(storyPreview: PimsterStoryPreview) {
    this.storyPreviews.forEach(sp => {
      sp.removeAnimation(StoryPreviewAnimation.Pulse);
    });

    storyPreview.addAnimation(StoryPreviewAnimation.Pulse);
  }

  private _restartPulse() {
    this._startPulse(this.storyPreviews[0]);
  }

  // Render Method
  render() {
    return html`
      <div @mouseleave="${this._onMouseLeave}" class="wrapper ${this.display}">
        ${this._getModuleTask.render({
          pending: () => null,
          // eslint-disable-next-line no-console
          error: console.error,
          complete: module =>
            pimsterGalleryWidgetRender({
              title: module.title,
              stories: module.stories,
              withTitle: this.withTitle,
            }),
        })}
      </div>
    `;
  }
}

export default PimsterGalleryWidget;
