/* eslint-disable no-shadow */

export enum CookieConsent {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Ask = 'ask',
}

export enum PimsterEvents {
  DOMContentLoaded = 'DOMContentLoaded',
  Click = 'click',
}

export enum Bundle {
  Product = 'product',
  Story = 'story',
}

export enum File {
  Image = 'image',
  Video = 'video',
}
