import { css } from 'lit';
import {
  ROUND_STORY_PREVIEW_DISPLAY_HEIGHT,
  SQUARE_STORY_PREVIEW_DISPLAY_HEIGHT,
} from '../pimster-story-preview/pimster-story-preview.constants';

export const pimsterGalleryWidgetStyle = css`
  .wrapper {
    display: grid;
    gap: 1em;
    width: 100%;
    padding-inline: 0;
    padding-block: 0.5em;
    grid-template-columns: repeat(1, 1fr);
  }

  .wrapper.square {
    ${SQUARE_STORY_PREVIEW_DISPLAY_HEIGHT}
  }

  .wrapper.round {
    ${ROUND_STORY_PREVIEW_DISPLAY_HEIGHT}
  }

  .title {
    margin: 0;
  }

  .stories {
    display: flex;
    overflow-x: auto;
    list-style: none;
    gap: 1.2em;
    margin: 0;
    width: 100%;
    scroll-behavior: smooth;
    scrollbar-width: none;

    /* Not to crop pulse animation */
    padding-inline: 1em;
    padding-block: 1em;
    margin-top: -1em;
    margin-left: -1em;
  }

  .story {
    cursor: pointer;
  }
`;
