import { AWS_CF_URL } from '../constants';
import { File } from '../enums';
import { FileFragment } from '../graphql/generated/graphql';

export const getAttributeFromElement = <T>({
  element,
  defaultValue,
  attributeName,
}: {
  element: Element;
  attributeName: string;
  defaultValue: T;
}): T => {
  const attributeValue = element.getAttribute(attributeName);

  if (attributeValue === null) {
    return defaultValue;
  }

  return attributeValue as T;
};

export const getStrapiAssetUrlOrFallback = (asset: FileFragment): string => {
  if (!asset.ext || !asset.hash) return asset.url;
  return `${AWS_CF_URL}/${asset.hash}${asset.ext}`;
};

export const getFileType = (file: FileFragment): File =>
  file.mime.split('/')[0] as File;
