/* eslint-disable no-shadow */

export enum Placement {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
}

export const STICKY_WIDGET_ELEMENT_NAME = 'pimster-sticky-widget';
export const STICKY_WIDGET_DEFAULT_PLACEMENT = Placement.BottomRight;
export const STICKY_WIDGET_DEFAULT_DISTANCE = '20px';
