import { ElementConfig } from '../types';
import { DIALOG_ELEMENT_NAME } from './pimster-dialog.constants';

const pimsterDialogConfig: ElementConfig = {
  name: DIALOG_ELEMENT_NAME,
  attributes: [
    {
      name: 'iframeSrc',
      defaultValue: '',
    },
    {
      name: 'iframeTitle',
      defaultValue: '',
    },
    {
      name: 'isLoading',
      defaultValue: true,
    },
    {
      name: 'isOpen',
      defaultValue: false,
    },
  ],
};

export default pimsterDialogConfig;
