import { TRIGGER_CONFIG_BASE_ATTRIBUTES } from '../constants';
import { ElementConfig } from '../types';
import {
  STICKY_WIDGET_DEFAULT_DISTANCE,
  STICKY_WIDGET_ELEMENT_NAME,
  STICKY_WIDGET_DEFAULT_PLACEMENT,
} from './pimster-sticky-widget.constants';

const pimsterStickyWidgetConfig: ElementConfig = {
  name: STICKY_WIDGET_ELEMENT_NAME,
  attributes: [
    ...TRIGGER_CONFIG_BASE_ATTRIBUTES,
    {
      name: 'backgroundImage',
      defaultValue: '',
    },
    {
      name: 'distance',
      defaultValue: STICKY_WIDGET_DEFAULT_DISTANCE,
    },
    {
      name: 'placement',
      defaultValue: STICKY_WIDGET_DEFAULT_PLACEMENT,
    },
    {
      name: 'text',
      defaultValue: '',
    },
  ],
};

export default pimsterStickyWidgetConfig;
