import { trackPageLoad } from './analytics/events/pageLoad';
import { PimsterEvents } from './enums';
import pimsterDialogConfig from './pimster-dialog/pimster-dialog.config';

/// Export all custom elements
export * from './pimster-dialog/index';
export * from './pimster-gallery-widget/index';
export * from './pimster-sticky-widget/index';
export * from './pimster-story-preview/index';
export * from './pimster-trigger/index';

/// Init logic : Insert a dialog
const init = () => {
  const { name } = pimsterDialogConfig;
  const dialog = document.createElement(name);
  document.body.appendChild(dialog);

  // Init analytics client
  trackPageLoad();
};

// Trigger logic on DOMLoaded or now
if (document.readyState === 'loading') {
  document.addEventListener(PimsterEvents.DOMContentLoaded, init);
} else {
  init();
}
