import { getAnalytics } from '..';
import { EVENTS } from '../constants';

export type OpenEventPayload = {
  widgetType: 'sticky' | 'gallery' | 'custom';
  company: string;
  product?: string;
  locale?: string;
  moduleId?: string;
  story?: string;
  storyOrder?: number;
  cookieConsent: string;
  skipOnboarding: boolean;
};

export const trackOpen = (payload: OpenEventPayload) => {
  const analytics = getAnalytics();
  analytics?.track(EVENTS.Open, payload);
};
