import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { pimsterStickyWidgetStyle } from './pimster-sticky-widget.style';
import pimsterStickyWidgetRender from './pimster-sticky-widget.render';
import pimsterStickyWidgetConfig from './pimster-sticky-widget.config';
import {
  STICKY_WIDGET_DEFAULT_DISTANCE,
  Placement,
  STICKY_WIDGET_DEFAULT_PLACEMENT,
} from './pimster-sticky-widget.constants';
import { CookieConsent } from '../enums';
import { TriggerPayload } from '../pimster-trigger/pimster-trigger.types';
import { trackImpression } from '../analytics/events/impression';

const { name } = pimsterStickyWidgetConfig;

@customElement(name)
class PimsterStickyWidget extends LitElement implements TriggerPayload {
  @property({ type: String })
  company = '';

  @property({ type: String })
  product = '';

  @property({ type: String })
  locale = undefined;

  @property({ type: `${CookieConsent}` })
  cookieConsent = CookieConsent.Ask;

  @property({ type: Boolean, converter: value => value === 'true' })
  skipOnboarding = false;

  @property({ type: String })
  backgroundImage = '';

  @property({ type: String })
  distance = STICKY_WIDGET_DEFAULT_DISTANCE;

  @property({ type: `${Placement}` })
  placement = STICKY_WIDGET_DEFAULT_PLACEMENT;

  @property({ type: String })
  text = '';

  static styles = pimsterStickyWidgetStyle;

  connectedCallback(): void {
    super.connectedCallback();
    trackImpression({
      widgetType: 'sticky',
      company: this.company,
      product: this.product,
      locale: this.locale,
    });
  }

  render() {
    return html`
      <pimster-trigger
        company="${this.company}"
        product="${this.product}"
        locale="${this.locale}"
        cookieConsent="${this.cookieConsent}"
        skipOnboarding="${this.skipOnboarding}"
      >
        ${pimsterStickyWidgetRender({
          backgroundImage: this.backgroundImage,
          distance: this.distance,
          placement: this.placement,
          text: this.text,
        })}
      </pimster-trigger>
    `;
  }
}

export default PimsterStickyWidget;
