import { PimsterStoryPreviewRenderProps } from './pimster-story-preview.types';
import { StoryPreviewDisplay } from './pimster-story-preview.constants';
import {
  roundStoryPreviewTemplate,
  squareStoryPreviewTemplate,
} from './pimster-story-preview.templates';

const pimsterStoryPreviewRender = ({
  backgroundImage,
  display,
  isHovered,
  isPlaying,
  isPulsating,
  isInsideAutoplay,
  text,
  video,
  withPlayIcon,
}: PimsterStoryPreviewRenderProps) => {
  switch (display) {
    case StoryPreviewDisplay.Round: {
      return roundStoryPreviewTemplate({
        backgroundImage,
        isHovered,
        isInsideAutoplay,
        isPlaying,
        isPulsating,
        text,
        video,
        withPlayIcon,
      });
    }

    case StoryPreviewDisplay.Square:
    default: {
      return squareStoryPreviewTemplate({
        backgroundImage,
        isHovered,
        isPlaying,
        text,
        video,
        withPlayIcon,
      });
    }
  }
};

export default pimsterStoryPreviewRender;
