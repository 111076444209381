type PluginConfig = {
  endpointURL: string;
};

type EventArgs = {
  payload: any;
  config: PluginConfig;
};

const DATA_API_ENDPOINT = 'https://api.data.pimster.app/t';

const sendData =
  typeof navigator.sendBeacon === 'function'
    ? (url: string, data: object) =>
        navigator.sendBeacon(url, JSON.stringify(data))
    : (url: string, data: object) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.send(JSON.stringify(data));
      };

export const pimsterDataPlugin = () => ({
  name: 'pimster-data',
  config: {
    endpointURL: DATA_API_ENDPOINT,
  },
  loaded: () => true,
  page: () => {
    console.error('[Analytics Plugin] Page event is not implemented');
  },
  track: ({ payload, config }: EventArgs) => {
    sendData(config.endpointURL, payload);
  },
});
