import { LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { pimsterDialogStyle } from './pimster-dialog.style';
import pimsterDialogRender from './pimster-dialog.render';
import pimsterDialogConfig from './pimster-dialog.config';
import {
  getCustomTriggerElements,
  constructIframe,
  getTriggerPayloadFromCustomTriggerElement,
} from './pimster-dialog.utils';
import { PimsterEvents } from '../enums';
import { trackImpression } from '../analytics/events/impression';
import { trackOpen } from '../analytics/events/open';
import { IFRAME_ELEMENT_ID } from './pimster-dialog.constants';

const privateOpen = Symbol('open');
const { name } = pimsterDialogConfig;

@customElement(name)
class PimsterDialog extends LitElement {
  [privateOpen]: boolean;

  @property({ type: String }) iframeSrc = '';

  @property({ type: String }) iframeTitle = '';

  @property({ type: Boolean }) isLoading = true;

  @property({ type: Boolean }) isOpen = false;

  static styles = pimsterDialogStyle;

  @query(`#${IFRAME_ELEMENT_ID}`)
  private iframe?: HTMLIFrameElement;

  private _wasFocused: HTMLElement | Element | null;

  constructor() {
    super();
    this._wasFocused = null;
    this[privateOpen] = false;
    this.isOpen = false;
  }

  updated(changedProperties: Map<string, unknown>) {
    if (changedProperties.has('isOpen') && this.isOpen) {
      window.document.body.style.overflow = 'hidden';
    }

    if (changedProperties.has('iframeSrc')) {
      this.isLoading = true;
    }
  }

  _onClose() {
    if (!this.iframe?.contentWindow) {
      console.error('No iframe window to post message to');
      return;
    }
    const srcURL = new URL(this.iframeSrc);
    const origin = `${srcURL.protocol}//${srcURL.host}`;
    this.iframe?.contentWindow?.postMessage({ action: 'pauseVideo' }, origin);
  }

  close() {
    this.isOpen = false;
    window.document.body.style.overflow = '';

    this._onClose();

    const closeEvent = new CustomEvent('dialog-closed');
    this.dispatchEvent(closeEvent);
  }

  _watchEscape(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  initialize() {
    const triggers = getCustomTriggerElements();
    triggers.forEach(trigger => {
      const triggerPayload = getTriggerPayloadFromCustomTriggerElement(trigger);
      trackImpression({
        widgetType: 'custom',
        company: triggerPayload.company,
        product: triggerPayload.product,
        locale: triggerPayload.locale,
      });
      trigger.addEventListener(PimsterEvents.Click, () => {
        trackOpen({
          widgetType: 'custom',
          ...triggerPayload,
        });
        const { src, title } = constructIframe(triggerPayload);
        this.isOpen = true;
        this.iframeSrc = src;
        this.iframeTitle = title;
      });
    });
  }

  connectedCallback() {
    super.connectedCallback();

    // Attach keydown listener
    this._watchEscape = this._watchEscape.bind(this);
    document.addEventListener('keydown', this._watchEscape);

    // Initialize
    this.initialize = this.initialize.bind(this);
    this.initialize();
  }

  disconnectedCallback() {
    document.removeEventListener('keydown', this._watchEscape);
    super.disconnectedCallback();
  }

  get open() {
    return this[privateOpen];
  }

  set open(isOpen) {
    this[privateOpen] = isOpen;
    const { shadowRoot } = this;
    const { activeElement } = document;

    if (isOpen) {
      this.isLoading = true;
      setTimeout(() => shadowRoot?.querySelector('button')?.focus());
      if (activeElement) {
        this._wasFocused = activeElement;
      }
      document.addEventListener('keydown', this._watchEscape);
      window.document.body.style.overflow = 'hidden';
    } else {
      if (this._wasFocused && 'focus' in this._wasFocused) {
        this._wasFocused.focus();
      }
      document.removeEventListener('keydown', this._watchEscape);
      window.document.body.style.overflow = '';
    }
  }

  markAsLoaded() {
    this.isLoading = false;
  }

  render() {
    return pimsterDialogRender({
      isOpen: this.isOpen,
      isLoading: this.isLoading,
      iframe: { src: this.iframeSrc, title: this.iframeTitle },
      close: () => this.close(),
      markAsLoaded: () => this.markAsLoaded(),
    });
  }
}

export default PimsterDialog;
