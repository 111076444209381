import { StyleInfo } from 'lit/directives/style-map';
import {
  STICKY_WIDGET_DEFAULT_DISTANCE,
  Placement,
} from './pimster-sticky-widget.constants';

export const getPlacementStyle = (
  placement: Placement,
  distance: string = STICKY_WIDGET_DEFAULT_DISTANCE
): StyleInfo => {
  let style: StyleInfo = {};

  if (placement.includes('left')) {
    style = { ...style, left: distance };
  }

  if (placement.includes('right')) {
    style = { ...style, right: distance };
  }

  if (placement.includes('top')) {
    style = { ...style, top: distance };
  }

  if (placement.includes('bottom')) {
    style = { ...style, bottom: distance };
  }

  return style;
};
