import { CookieConsent, File } from '../enums';
import { FileFragment } from '../graphql/generated/graphql';
import { getFileType } from './getters';

export const isCookieConsent = (value: any): value is CookieConsent =>
  Object.values(CookieConsent).includes(value);

export const isSomething = <T>(value: T | null | undefined): value is T =>
  value !== null && typeof value !== 'undefined';

export const isImage = (file: FileFragment) => getFileType(file) === File.Image;

export const isVideo = (file: FileFragment) => getFileType(file) === File.Video;
