import { html } from 'lit';
import { PimsterGalleryWidgetRenderProps } from './pimster-gallery-widget.types';

const pimsterGalleryWidgetRender = ({
  title,
  stories,
  withTitle,
}: PimsterGalleryWidgetRenderProps) => html`
  ${withTitle ? html`<h2 class="title">${title}</h2>` : ''}
  <ul class="stories">
    ${stories.map(story => html`<li class="story">${story}</li>`)}
  </ul>
`;

export default pimsterGalleryWidgetRender;
