import { css } from 'lit';
import {
  ROUND_STORY_PREVIEW_DISPLAY_HEIGHT,
  SQUARE_STORY_PREVIEW_DISPLAY_HEIGHT,
  STORY_PREVIEW_ANIMATION_DURATION_IN_SECONDS,
} from './pimster-story-preview.constants';

export const pimsterStoryPreviewStyle = css`
  .hidden {
    display: none !important;
  }

  .play-icon {
    fill: none;
    stroke: white;
    stroke-linecap: round;
    stroke-linejoin: round;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: keep-all;
  }

  .square-wrapper {
    position: relative;
    width: 10em;
    ${SQUARE_STORY_PREVIEW_DISPLAY_HEIGHT};
    overflow: hidden;
    border-radius: 0.5em;
    background-size: cover;
    background-color: lightgrey;
    background-position: center;
    z-index: 1;
  }

  .square-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: end;
    flex-direction: column;
    background: linear-gradient(#f6f6f600, #24242440 75%, #24242480);
    z-index: 2;
  }

  .square-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play-icon.square {
    width: 4em;
    height: 4em;
    stroke-width: 0.2em;
  }

  .square-text {
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    margin: 1em;
    padding: 0;
  }

  .round-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 5em;
    ${ROUND_STORY_PREVIEW_DISPLAY_HEIGHT};
  }

  .round-content-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    width: 100%;
    height: 5em;
    border-radius: 50%;
    border-width: 0.2em;
    border-style: solid;
    border-color: var(--border-color);
  }

  .round-content-wrapper.pulsating {
    animation: pulse ${STORY_PREVIEW_ANIMATION_DURATION_IN_SECONDS / 4}s
      infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
  }

  .round-background-image {
    position: relative;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    width: calc(100% - 0.5em);
    height: calc(100% - 0.5em);
    background-size: cover;
    background-color: lightgrey;
    background-position: center;
  }

  .round-background-image.opaque {
    filter: brightness(0.7);
  }

  .round-video {
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }

  .play-icon.round {
    width: 2em;
    height: 2em;
    stroke-width: 0.1em;
  }

  .round-text {
    text-align: center;
    color: black;
    font-size: 0.75em;
    font-weight: 500;
    margin: 0;
    width: 100%;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(from var(--border-color) r g b / 70%);
    }
    20% {
      box-shadow: 0 0 0 0 rgb(from var(--border-color) r g b / 70%);
    }

    70% {
      box-shadow: 0 0 0 0.7em rgb(from var(--border-color) r g b / 10%);
    }

    100% {
      box-shadow: 0 0 0 0.3em rgb(from var(--border-color) r g b / 10%);
    }
  }
`;
