import { getAnalytics } from '..';
import { EVENTS } from '../constants';

export type ImpressionEventPayload = {
  widgetType: 'sticky' | 'gallery' | 'custom';
  company: string;
  product?: string;
  locale?: string;
  moduleId?: string;
  isAutoplay?: boolean;
};

export const trackImpression = (payload: ImpressionEventPayload) => {
  const analytics = getAnalytics();
  analytics?.track(EVENTS.Impression, payload);
};
