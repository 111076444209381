import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import pimsterTriggerConfig from './pimster-trigger.config';
import { TriggerPayload } from './pimster-trigger.types';
import { CookieConsent, PimsterEvents } from '../enums';
import PimsterDialog from '../pimster-dialog/pimster-dialog';
import {
  constructIframe,
  getDialogOrThrow,
} from '../pimster-dialog/pimster-dialog.utils';
import { trackOpen } from '../analytics/events/open';
import { retryUntilReturnOrThrow } from '../utils/retry';

const { name } = pimsterTriggerConfig;

@customElement(name)
class PimsterTrigger extends LitElement implements TriggerPayload {
  @property({ type: String })
  company = '';

  @property({ type: String })
  product = '';

  @property({ type: String })
  locale = '';

  @property({ type: `${CookieConsent}` })
  cookieConsent = CookieConsent.Ask;

  @property({ type: Boolean, converter: value => value === 'true' })
  skipOnboarding = false;

  @property({ type: String })
  module?: string = undefined;

  @property({ type: String })
  story?: string = undefined;

  @property({ type: Number })
  storyOrder?: number = undefined;

  private dialog: PimsterDialog | null = null;

  private iframeTitle: string | null = null;

  private iframeSrc: string | null = null;

  private getTriggerPayload(): TriggerPayload {
    return {
      company: this.company,
      product: this.product,
      locale: this.locale,
      cookieConsent: this.cookieConsent,
      skipOnboarding: this.skipOnboarding,
      module: this.module,
      story: this.story,
      storyOrder: this.storyOrder,
    };
  }

  prefetchSrc() {
    if(!this.iframeSrc) throw Error("Invalid iFrame src, can't prefetch")
    const prefetchLink = document.createElement("link");
    prefetchLink.rel = "prefetch";
    prefetchLink.href = this.iframeSrc;
    document.head.appendChild(prefetchLink);
  }

  async initialize() {
    await this.retryGetDialog();

    const {src, title} = constructIframe(this.getTriggerPayload())
    this.iframeSrc = src;
    this.iframeTitle = title;
    
    this.prefetchSrc()
  }

  async retryGetDialog() {
    try {
      const dialog = await retryUntilReturnOrThrow<PimsterDialog>({
        action: getDialogOrThrow,
      });
      this.dialog = dialog;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'Could not get pimster-dialog from the current document, please verify that you included the script in your document',
        error
      );
    }
  }

  openDialog() {
    trackOpen({
      widgetType: this.story ? 'gallery' : 'sticky',
      company: this.company,
      product: this.product,
      locale: this.locale,
      moduleId: this.module,
      story: this.story,
      storyOrder: this.storyOrder,
      cookieConsent: this.cookieConsent,
      skipOnboarding: this.skipOnboarding,
    });
    if (!this.dialog) throw Error('No dialog to open');
    if (!this.iframeSrc) throw Error('Invalid iFrame src in trigger')
    this.dialog.isOpen = true;
    this.dialog.iframeSrc = this.iframeSrc;
    this.dialog.iframeTitle = this.iframeTitle || "";
  }

  connectedCallback() {
    super.connectedCallback();
    this.initialize = this.initialize.bind(this);
    this.initialize();
  }

  handleSlotChange(e: any) {
    const childNodes = e.target.assignedNodes({ flatten: true });
    childNodes.map((node: Element) =>
      node.addEventListener(PimsterEvents.Click, this.openDialog.bind(this))
    );
  }

  setCompany(company: string) {
    this.company = company;
    return this;
  }

  setProduct(product: string) {
    this.product = product;
    return this;
  }

  setLocale(locale: string) {
    this.locale = locale;
    return this;
  }

  setCookieConsent(cookieConsent: CookieConsent) {
    this.cookieConsent = cookieConsent;
    return this;
  }

  setModule(module: string) {
    this.module = module;
    return this;
  }

  setStory(story: string) {
    this.story = story;
    return this;
  }

  setStoryOrder(storyOrder: number) {
    this.storyOrder = storyOrder;
    return this;
  }

  render() {
    return html`<slot @slotchange=${this.handleSlotChange}></slot>`;
  }
}

export default PimsterTrigger;
