import { html } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { PimsterStickyWidgetRenderProps } from './pimster-sticky-widget.types';
import { getPlacementStyle } from './pimster-sticky-widget.utils';

const pimsterStickyWidgetRender = ({
  backgroundImage,
  distance,
  placement,
  text,
}: PimsterStickyWidgetRenderProps) =>
  html`
    <div
      class="wrapper"
      style=${styleMap({ ...getPlacementStyle(placement, distance) })}
    >
      <pimster-story-preview
        backgroundImage="${backgroundImage}"
        text="${text}"
      ></pimster-story-preview>
    </div>
  `;

export default pimsterStickyWidgetRender;
