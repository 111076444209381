import { html, LitElement, PropertyValueMap, PropertyValues } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import pimsterStoryPreviewRender from './pimster-story-preview.render';
import { pimsterStoryPreviewStyle } from './pimster-story-preview.style';
import pimsterStoryPreviewConfig from './pimster-story-preview.config';
import {
  STORY_PREVIEW_DEFAULT_ANIMATIONS,
  STORY_PREVIEW_DEFAULT_BORDER_COLOR,
  STORY_PREVIEW_DEFAULT_DISPLAY,
  StoryPreviewAnimation,
  StoryPreviewDisplay,
} from './pimster-story-preview.constants';
import {
  StoryPreviewMouseEnterEvent,
  StoryPreviewMouseLeaveEvent,
} from './pimster-story-preview.types';
import {
  buildStoryPreviewEvent,
  hasAutoplay,
  hasOnHover,
  hasPulse,
} from './pimster-story-preview.utils';

const { name } = pimsterStoryPreviewConfig;

@customElement(name)
class PimsterStoryPreview extends LitElement {
  // Properties
  @property({ type: Array<StoryPreviewAnimation> })
  animations = STORY_PREVIEW_DEFAULT_ANIMATIONS;

  @property({ type: String })
  backgroundImage = '';

  @property({ type: String })
  borderColor = STORY_PREVIEW_DEFAULT_BORDER_COLOR;

  @property({ type: `${StoryPreviewDisplay}` })
  display = STORY_PREVIEW_DEFAULT_DISPLAY;

  @property({ type: String })
  text = '';

  @property({ type: String })
  video?: string = undefined;

  @property({ type: Boolean, converter: value => value === 'true' })
  noPlayIcon = false;

  // Queries
  @query('video')
  htmlVideo?: HTMLVideoElement;

  // States
  @state()
  private _isHovered = false;

  @state()
  private _isPlaying = false;

  @state()
  private _isPulsating = false;

  @state()
  private _isInsideAutoplay = false;

  // Static Styles
  static styles = pimsterStoryPreviewStyle;

  // Setter Methods
  addAnimation(animation: StoryPreviewAnimation) {
    this.removeAnimation(animation);
    this.setAnimations([...this.animations, animation]);
  }

  removeAnimation(animation: StoryPreviewAnimation) {
    this.setAnimations(this.animations.filter(a => a !== animation));
  }

  setAnimations(animations: StoryPreviewAnimation[]) {
    this.animations = animations;
    return this;
  }

  setBackgroundImage(backgroundImage: string) {
    this.backgroundImage = backgroundImage;
    return this;
  }

  setBorderColor(borderColor: string) {
    this.borderColor = borderColor;
    return this;
  }

  setDisplay(display: StoryPreviewDisplay) {
    this.display = display;
    return this;
  }

  setIsInsideAutoplay(isInsideAutoplay: boolean) {
    this._isInsideAutoplay = isInsideAutoplay;
    return this;
  }

  setVideo(video: string) {
    this.video = video;
    return this;
  }

  setText(text: string) {
    this.text = text;
    return this;
  }

  setNoPlayIcon(noPlayIcon: boolean) {
    this.noPlayIcon = noPlayIcon;
    return this;
  }

  private _setIsHovered(isHovered: boolean) {
    this._isHovered = isHovered;
    return this;
  }

  private _setIsPlaying(isPlaying: boolean) {
    this._isPlaying = isPlaying;
    return this;
  }

  private _setIsPulsating(isPulsating: boolean) {
    this._isPulsating = isPulsating;
    return this;
  }

  // Event Handlers
  private _onMouseEnter() {
    if (hasOnHover(this.animations)) {
      this.dispatchEvent(
        buildStoryPreviewEvent<StoryPreviewMouseEnterEvent>('mouseenter', {
          storyPreview: this,
        })
      );
      this._setIsHovered(true);
    }
  }

  private _onMouseLeave() {
    if (hasOnHover(this.animations)) {
      this.dispatchEvent(
        buildStoryPreviewEvent<StoryPreviewMouseLeaveEvent>('mouseleave', {
          storyPreview: this,
        })
      );
      this._setIsHovered(false);
    }
  }

  // Lifecycle Methods
  connectedCallback() {
    super.connectedCallback();
    this.style.setProperty('--border-color', this.borderColor);
    this.toggleAnimations();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.htmlVideo?.removeEventListener('ended', () => this.restartVideo());
  }

  firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.htmlVideo?.addEventListener('ended', () => this.restartVideo());
  }

  protected update(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ) {
    super.update(changedProperties);

    if (changedProperties.has('animations')) {
      this.toggleAnimations();
    }
  }

  // Animation Control Methods
  toggleAnimations() {
    this._setIsPulsating(hasPulse(this.animations));

    if (hasAutoplay(this.animations)) {
      this.restartVideo();
    } else {
      this.stopVideo();
    }
  }

  // Video Control Methods
  pauseVideo() {
    this._setIsPlaying(false);
    this.htmlVideo?.pause();
  }

  async playVideo() {
    try {
      this._setIsPlaying(true);
      await this.htmlVideo?.play();
    } catch (err) {
      this.pauseVideo();
    }
  }

  resetVideo() {
    if (!this.htmlVideo) return;
    this.htmlVideo.currentTime = 0;
  }

  async restartVideo() {
    this.resetVideo();
    this.playVideo();
  }

  stopVideo() {
    this.pauseVideo();
    this.resetVideo();
  }

  // Render Method
  render() {
    return html`
      <div
        @mouseenter="${this._onMouseEnter}"
        @mouseleave="${this._onMouseLeave}"
      >
        ${pimsterStoryPreviewRender({
          backgroundImage: this.backgroundImage,
          display: this.display,
          isHovered: this._isHovered,
          isInsideAutoplay: this._isInsideAutoplay,
          isPlaying: this._isPlaying,
          isPulsating: this._isPulsating,
          text: this.text,
          video: this.video,
          withPlayIcon: !this.noPlayIcon,
        })}
      </div>
    `;
  }
}

export default PimsterStoryPreview;
