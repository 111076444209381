import { html } from 'lit';

export const skeleton = html`
  <div class="skeleton">
    <div class="skeleton-header">
      <div class="skeleton-circle"></div>
      <div class="skeleton-text short"></div>
    </div>
    <div class="skeleton-footer">
      <div class="skeleton-text long"></div>
      <div class="skeleton-text short"></div>
    </div>
  </div>
`;
