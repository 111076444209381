import { CookieConsent } from './enums';

// Strings
export const PIMSTER_ATTRIBUTE_PREFIX = 'data-pimster-';
export const ENVIRONMENT = process.env.PIMSTER_ENVIRONMENT || 'production';
export const GRAPHQL_URL =
  process.env.STRAPI_GRAPHQL || 'https://cms.api.staging.pimster.app/graphql';
export const AWS_CF_URL =
  process.env.AWS_CF_URL || 'https://media.staging.pimster.app';

// Arrays
export const TRIGGER_CONFIG_BASE_ATTRIBUTES = [
  {
    name: 'company',
    defaultValue: '',
  },
  {
    name: 'product',
    defaultValue: undefined,
  },
  {
    name: 'locale',
    defaultValue: undefined,
  },
  {
    name: 'cookieConsent',
    defaultValue: CookieConsent.Ask,
  },
  {
    name: 'skipOnboarding',
    defaultValue: false,
  },
];
