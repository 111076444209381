import { ENVIRONMENT, PIMSTER_ATTRIBUTE_PREFIX } from '../constants';
import { isCookieConsent } from '../utils/guards';
import { DIALOG_ELEMENT_NAME } from './pimster-dialog.constants';
import PimsterDialog from './pimster-dialog';
import {
  CustomTriggerElement,
  IframeSrcQueryParams,
} from './pimster-dialog.types';
import { TriggerPayload } from '../pimster-trigger/pimster-trigger.types';
import { Bundle } from '../enums';

export const getDialogOrThrow = (): PimsterDialog => {
  const dialog = document.querySelector(DIALOG_ELEMENT_NAME);
  if (!dialog) throw new Error('Pimster dialog could not be found.');
  return dialog as PimsterDialog;
};

export const constructQueryParams = (params: IframeSrcQueryParams) => {
  const queryParams = Object.keys(params)
    .map(key => {
      const value = params[key as keyof IframeSrcQueryParams];

      if (typeof value !== 'undefined') {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }

      return '';
    })
    .join('&');

  return `?${queryParams}`;
};

export const constructPimUrl = ({
  company,
  product,
  locale,
  cookieConsent,
  skipOnboarding,
  module,
  story,
  storyOrder,
}: TriggerPayload) => {
  const queryParams = constructQueryParams({
    utm_source: 'website',
    utm_medium: 'iframe',
    cookie_consent: cookieConsent,
    skip_onboarding: skipOnboarding,
    bundle: story ? Bundle.Story : Bundle.Product,
    ...(module && { module }),
    ...(storyOrder && { story_order: storyOrder }),
  });

  let url = '';

  switch (ENVIRONMENT) {
    case 'development':
      url = `http://${company}.localhost:3000`;
      break;
    case 'staging':
      url = `https://${company}.staging.pimster.app`;
      break;
    case 'production':
    default:
      url = `https://${company}.pimster.app`;
      break;
  }

  if (locale) {
    url += `/${locale}`;
  }

  if (product) {
    url += `/${product}`;
  }

  if (story) {
    url += `/story/${story}`;
  }

  url += queryParams;

  return url;
};

export const constructIframeTitle = (companySlug: string) => {
  const companyWithFirstLetterCapitalized =
    companySlug.charAt(0).toUpperCase() + companySlug.slice(1);

  return `${companyWithFirstLetterCapitalized} Assistant`;
};

export const getTriggerPayloadFromCustomTriggerElement = (
  element: CustomTriggerElement
): TriggerPayload => {
  const { dataset } = element;

  const company = dataset.pimsterCompany;
  const product = dataset.pimsterProduct;
  const locale = dataset.pimsterLocale;
  const cookieConsent = dataset.pimsterCookieconsent;
  const skipOnboarding = dataset.pimsterSkiponboarding;

  return {
    company,
    product,
    locale,
    cookieConsent,
    skipOnboarding,
  };
};

export const constructIframe = (payload: TriggerPayload) => {
  const src = constructPimUrl(payload);
  const title = constructIframeTitle(payload.company);
  return { src, title };
};

export const isValidCustomTriggerElementOrThrow: (
  element: Element
) => asserts element is CustomTriggerElement = element => {
  const prefix = PIMSTER_ATTRIBUTE_PREFIX;
  const errorKey = 'Pimster trigger has been wrongly configured:';

  const _buildError = (message: string) => new Error(`${errorKey} ${message}`);

  if (!element.hasAttribute(`${prefix}company`)) {
    throw _buildError('company is missing');
  }

  const cookieConsent = element.getAttribute(`${prefix}cookieConsent`);

  if (!isCookieConsent(cookieConsent)) {
    throw _buildError(`wrong value ('${cookieConsent}') for cookieConsent`);
  }

  return true;
};

export const getCustomTriggerElements = (): CustomTriggerElement[] => {
  const declaredTriggers: Element[] = [];

  document
    .querySelectorAll(`[data-pimster-id='pimster-trigger']`)
    ?.forEach(dt => declaredTriggers.push(dt));

  return declaredTriggers.filter(
    isValidCustomTriggerElementOrThrow
  ) as CustomTriggerElement[];
};
