import {
  DynamicStoryPreviewsModuleFragment,
  Enum_Modules_Type,
  StoryPreviewsModuleFragment,
} from '../graphql/generated/graphql';
import { isVideo } from '../utils/guards';
import { GALLERY_WIDGET_ELEMENT_NAME } from './pimster-gallery-widget.constants';
import {
  ValidDynamicStoryPreviewComponent,
  ValidStoryPreviewComponent,
  ValidStoryPreviewsModule,
} from './pimster-gallery-widget.types';

export const buildGalleryWidgetError = (message: string, errorKey?: string) =>
  new Error(
    `${GALLERY_WIDGET_ELEMENT_NAME}${
      errorKey ? ` ${errorKey}` : ''
    }: ${message}`
  );

export const buildGalleryWidgetConfigError = (message: string) =>
  buildGalleryWidgetError(message, 'has been wrongly configured');

export const isValidStoryPreviewsModuleOrThrow: (
  module: StoryPreviewsModuleFragment | DynamicStoryPreviewsModuleFragment,
  config: { company: string; product: string }
) => asserts module is ValidStoryPreviewsModule = (module, config) => {
  if (module.__typename !== 'Modules') {
    throw buildGalleryWidgetConfigError('module has a wrong __typename');
  }

  if (module.Type !== Enum_Modules_Type.Stories) {
    throw buildGalleryWidgetConfigError("module is not of type 'stories'");
  }

  if (!module.Content || module.Content.length < 1) {
    throw buildGalleryWidgetError('module has no content');
  }

  if (module.Product?.Company?.Slug !== config.company) {
    throw buildGalleryWidgetConfigError('company mismatch');
  }

  if (module.Product?.Slug !== config.product) {
    throw buildGalleryWidgetConfigError('product mismatch');
  }

  return true;
};

export const isValidStoryPreviewComponent = (
  value: ValidStoryPreviewsModule['Content'][0]
): value is ValidStoryPreviewComponent => {
  if (value.__typename !== 'ComponentContentStory') return false;
  if (!value.Story) return false;
  if (!value.Story.locale) return false;
  if (!value.Story.Slug) return false;
  if (!value.Story.PreviewImage?.url) return false;
  return true;
};

export const isValidDynamicStoryPreviewComponent = (
  value: ValidStoryPreviewsModule['Content'][0]
): value is ValidDynamicStoryPreviewComponent => {
  if (!isValidStoryPreviewComponent(value)) return false;
  if (!('StoryPages' in value.Story)) return false;
  if (value.Story.StoryPages?.length !== 1) return false;
  if (!value.Story.StoryPages[0].Media) return false;
  if (!isVideo(value.Story.StoryPages[0].Media)) return false;
  return true;
};
